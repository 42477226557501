<template>
	<div class="app-container">
		<el-card class="filter-container" shadow="never">
			<div>
				<i class="el-icon-search"></i>
				<span>筛选搜索</span>
				<el-button style="float: right" @click="searchAdminList()" type="primary" size="small">查询结果</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="listQuery" size="small" label-width="140px">
					<p>
						<el-form-item label="标题："><el-input style="width: 203px" v-model="filter[0].val" placeholder="请输入标题/关键字"></el-input></el-form-item>
					</p>
				</el-form>
			</div>
		</el-card>
		<el-card class="operate-container" shadow="never">
			<i class="el-icon-tickets"></i>
			<span>消息列表</span>
			<el-button class="btn-add" @click="addMessage" size="mini">添加消息</el-button>
		</el-card>
		<div class="table-container">
			<el-table ref="adminTable" :data="list" stripe style="width: 100%" @selection-change="handleSelectionChange" v-loading="listLoading" border>
				<el-table-column type="selection" width="60" align="center"></el-table-column>
				<el-table-column label="标题" fixed align="center">
					<template slot-scope="scope">
						{{ scope.row.title }}
					</template>
				</el-table-column><el-table-column label="内容"  align="center">
					<template slot-scope="scope">
						{{ scope.row.content }}
					</template>
				</el-table-column>
				<el-table-column label="状态" width="140" align="center">
					<template slot-scope="scope">
						<p>
							发送：
							<el-switch @change="handleStatus(scope.row)" :active-value="1" :inactive-value="0" v-model="scope.row.status"></el-switch>
						</p>
					</template>
				</el-table-column>
				<el-table-column label="创建时间" width="180" align="center">
					<template slot-scope="scope">
						{{ scope.row.createdTxStamp | formatTime }}
					</template>
				</el-table-column>
				<el-table-column label="操作" fixed="right" width="300" align="center">
					<template slot-scope="scope">
						 <el-button size="mini"  @click="addMessage(scope.row)">编辑</el-button>
						   <el-button size="mini" type="success" @click="handleStatus(scope.row)">发送</el-button>
							<el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination-container">
			<el-pagination
				background
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				layout="total, sizes,prev, pager, next,jumper"
				:page-size="listQuery.pageSize"
				:page-sizes="[5, 10, 15]"
				:current-page.sync="listQuery.page"
				:total="total"
			></el-pagination>
		</div>

		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%">
			<el-form :model="message" :rules="rules" ref="messageFrom" label-width="150px">
				<el-form-item label="标题：" prop="title"><el-input style="width: 250px" v-model="message.title"></el-input></el-form-item>
				<el-form-item label="内容：" prop="content"><el-input type="textarea" :rows="2" style="width: 250px" placeholder="请输入消息内容" v-model="message.content"></el-input></el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('messageFrom')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { formatDate } from '@/plugins/date.js';
const defaultListQuery = {
	entity: 'PdYsfExamMessage',
	page: 1,
	pageSize: 5
};
const defaultMessage = {
	title: '',
	content:'',
	status: 0 ,
};
export default {
	name: 'adminList',
	data() {
		return {
			dialogVisible: false,
			dialogTitle: '',
			filter: [
				{
					col: 'title',
					type: 'like',
					val: ''
				}
			],
			list: [],
			total: null,
			listLoading: true,
			message: Object.assign({}, defaultMessage),
			listQuery: Object.assign({}, defaultListQuery),
			rules: {
				title: [{ required: true, message: '请输入消息标题', trigger: 'blur' }],
				content: [{ required: true, message: '请输入消息内容', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.getList();
	},
	filters: {
		formatTime(time) {
			if (time == null || time === '') {
				return 'N/A';
			}
			let date = new Date(time);
			return formatDate(date, 'yyyy-MM-dd hh:mm:ss');
		}
	},
	methods: {
		getList() {
			this.listLoading = true;
			this.listQuery.filter = JSON.stringify(this.filter);
			this.$comjs.ajax.getAjax('/jqGrid/commonlist', this.listQuery, this, res => {
				this.listLoading = false;
				this.list = res.rows;
				this.total = res.total;
				this.totalPage = res.total;
			});
		},
		handleDelete(index, row) {
			this.$confirm('是否要删除该用户', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id: row.id,
					entity: 'PdYsfExamMessage'
				};
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					this.$message({
						message: '删除成功',
						type: 'success',
						duration: 1000
					});
					this.getList();
				});
			});
		},
		handleSizeChange(val) {
			this.listQuery.pageNum = 1;
			this.listQuery.pageSize = val;
			this.getList();
		},
		handleCurrentChange(val) {
			this.listQuery.pageNum = val;
			this.getList();
		},
		searchAdminList() {
			this.listQuery.pageNum = 1;
			this.getList();
		},
		addMessage(row) {
			if(row){
				this.dialogVisible = true;
				this.dialogTitle = '编辑消息';
				this.message={
					id:row.id,
					title:row.title,
					content:row.content,
					status:row.status
				}
			}else{
				this.dialogVisible = true;
				this.dialogTitle = '添加消息';
				this.message = Object.assign({}, defaultMessage);
			}
			
		},
		handleStatus(row){
			var that = this;
			let data={
				ids:row.id,
				status:row.status,
				entity:'PdYsfExamMessage'
			}
			this.$confirm('确认该操作！', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				that.$comjs.ajax.postAjax('/jqGrid/changeStatusMore', data, this, res => {
					that.$message({
						message: res.message,
						type: 'success',
						duration: 1000
					});
					that.getList();
				});
			});
		},
		onSubmit(formName) {
			var that = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {
						entity:'PdYsfExamMessage',
						model:'questionBank',
						dataObject: JSON.stringify(this.message)
					};
					this.$confirm('是否提交数据', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						that.$comjs.ajax.postAjax('/jqGrid/commonDataSave', data, this, res => {
							this.dialogVisible = false;
							that.$refs[formName].resetFields();
							that.message = Object.assign({}, defaultMessage);
							that.$message({
								message: '保存成功！',
								type: 'success',
								duration: 1000
							});
							that.getList();
						});
					});
				}
			});
		}
	}
};
</script>
<style scoped>
.app-container {
	padding: 20px;
}

.operate-container {
	margin-top: 20px;
}
.operate-container .btn-add {
	float: right;
}
.batch-operate-container,
.pagination-container {
	display: inline-block;
	margin-top: 20px;
}
.pagination-container {
	float: right;
}
</style>
